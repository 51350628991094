* {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-callout: none;
}
.text-fragment-input {
  -moz-user-select: initial;
  -webkit-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  touch-callout: initial;
}
html {
    background-color: #000;
    position:fixed;
}
body {
    height: 100vh;
    margin: 0;
    max-height: 100vh;
    max-width:100vw;
    overflow: hidden;
    position:fixed;
    width:100vw;
}
input[type="button"] {
    background: lawngreen;
    border: none;
    box-shadow: 0 1px 1px grey;
    color: darkslategrey;
    cursor: pointer;
    padding: 4px 8px;
    /*right top blur color*/
}
input[type="button"]:hover {
    box-shadow: 0 2 2px grey;
    transform: translate(0px, -1px);
}
input[type="button"]:active {
    box-shadow: 0 0 1px grey;
    transform: translate(0px, 1px);
}
input[type="button"]:disabled {
    background: darkgrey;
    box-shadow: 0 0 2px red;
}
input[type="button"]:disabled:hover {
    box-shadow: 0 0 2px red;
    cursor: wait;
    transform: translate(0px, 0px);
}
input[type="button"]:disabled:active {
    box-shadow: 0 0 0 red;
    transform: translate(0px, 0px);
}
.overlay * {
  box-sizing: border-box;

}
.overlay {
  /* will be set to display:flex by js */
  display: none;
  margin: 0;
  padding: 0;
  position: fixed;
  width:100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.ui-container {
  font-family: 'Arial';
  background: #FFFE;
  border: 4px solid #000D;
  font-size: 1.5rem;
  display: none;
  max-width: 60%;
  padding: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
}
.ui-container > label {
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ui-container input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-right: 7px;
}
.ui-container > input {
  border-color: black;
  padding: 0.5rem;
  border-style: solid;
  font-size: 1.5rem;
  text-align: center;
}
.ui-container > button {
  font-size: 1.5rem;
  padding: 0.5rem;
  align-self: center;
  background-color: #39e470;
  color: black;
  cursor: pointer;
}
.ui-container .overlay-xmark {
  display: flex;
  position: absolute;
  left: -5px;
  top: -5px;
  padding: 7px;
  border: 2px solid black;
  cursor: pointer;
  background-color: #FFFD;
}
.overlay-xmark svg {
  width: 20px;
  height: 20px;
}
.overlay-xmark:hover {
  background: #ffff;
}
#zoom-page {
    display: block;
    height: 100vh;
    width: 100vw;
}
#zoom-sketch > canvas {
  display: block;
  /*position: absolute;*/
  /*top: 200px;*/
}

.permissions-ui {
  z-index: 4;
}
.permissions-ui * {
  box-sizing: border-box;
}

.surface * {
  box-sizing: border-box;
}

.surface {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 30px;
  display: flex;
  font-family: 'Arial';
  color: #888;
  flex-direction: column;
}

.surface-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  gap: 20px;
}

.surface-item {
  background: #eeeeee;
  font-size: 1.2rem;
  border: 2px solid black;
  color: #000000;
  padding: 5px;
  cursor: pointer;
  pointer-events: all;
  display: inline-flex;
  align-items:center;
}

.entr-cell .surface-item {
  color: #222222;
  background: #eeeeee;
}

.entr-cell .surface-item:hover {
  color: #000000;
  background: #ffffff;
}

.space-info {
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  padding: 7px;
}

.user-info {
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  padding: 7px;
}

.surface-item.text {
  background: #000;
  border: 2px solid #eee;
  color: #eee;
  padding: 6px 8px;
  cursor: default;
}

.surface-item.text:hover {
  background: #000;
  color: #eee;
}

.perf-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tool-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 30px;
  left: 0px;
  right: 0px;
  gap: 10px;
}

.tool-cell .toolbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  position: relative;
}

.tool-cell .shelf {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: no-wrap;
  gap: 10px;
  position: fixed;
  right: 10px;
  bottom: 70px;
}

.tool-cell.collapsed .shelf {
  display: none;
}

.surface-item.toshelf {
  top: 10px;
}

.help-cell {
  display: flex;
  flex-direction: column;
}

.menu-head {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.collapse-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
}

.collapse-box .surface-item {
  font-size: 1.1rem;
}

.icon {
  display: flex;
  align-items: center;
  padding: 10px;
}
.icon svg {
  width: 15px;
  height: 15px;
  pointer-events: none;
}
.icon:hover {
  background-color: white;
}
.surface-item.noloop {
  border-left-color: #ffffff;
  border-left-width: 4px;
}
.surface-item.hide {
  display: none;
}

.surface-item.darken,
.darken > .keybinding  {
  background-color: #6f6f6f;
}

.surface-item.context {
  position: relative;
}
.surface-item.context .t {
  position: absolute;
  background: inherit;
  top: -10px;
  left: -7px;
  padding: 3px;
  border-radius: 3px;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
}
.t svg {
  width: 15px;
  height: 15px;
}
.surface-item.context .w {
  position: absolute;
  background: inherit;
  bottom: -7px;
  left: -10px;
  padding: 3px;
  border-radius: 3px;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
}
.w svg {
  width: 15px;
  height: 15px;
}
.surface-item.context .s {
  position: absolute;
  bottom: -7px;
  left: -7px;
  width: 14px;
  height: 14px;
}
.surface-item.context .c {
  background-color: black;
  position: absolute;
  bottom: -15px;
  right: -15px;
  padding: 5px;
  font-size: .9rem;
}

.icon .r {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background: #eeeeee;
  fill: black;
  padding: 4px;
  display: flex;
  align-items:center;
}

.surface-item span {
  pointer-events: none;
}

.icon.large2 {
  padding: 9px;
}

.icon.large2 svg {
  width: 17px;
  height: 17px;
}

.icon.large4 {
  padding: 8px;
}

.icon.large4 svg {
  width: 19px;
  height: 19px;
}

.icon.large6 {
  padding: 7px;
}

.icon.large6 svg {
  width: 21px;
  height: 21px;
}

.icon.large10 {
  padding: 5px;
}

.icon.large10 svg {
  width: 25px;
  height: 25px;
}

.surface-item.selected {
  border-color: white;
  background: black;
  fill: white;
}

.surface-item {
  position: relative;
}
.surface-item.nokey .keybinding {
  display: none;
}
.keybinding {
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -10px;
  right: -7px;
  border-radius: 5px;
  padding-top: 2px;
  width: 17px;
  height: 17px;
  background: #fff;
  border: 1px solid black;
  font-size: .7rem;
}
.keybinding.fit {
  width: auto;
  padding-left: 4px;
  padding-right: 4px;
}
.keybinding.wide {
  width: 32px;
}
.keybinding.top {
  top: -10px;;
}

.keybinding.spaceicon {
  padding-top: 0px;
}
.keybinding.spaceicon svg {
  width: 15px;
  height: 10px;
}

.tips-box {
  font-size: .8rem;
  padding: 5px;
  border-width: 1px;
}

.messages-box {
  z-index: 7;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 20px;
  overflow: hidden;
  gap: 10px;
}

.message-item {
  font-size: 1.2rem;
  color: #fff;
  background-color: black;
  padding: 7px;
}

.message-item.error {
  color: red;
}

@keyframes disappear {
  from { opacity: 1; }
  to   { opacity: 0; }
}

.message-item.disappear {
  animation-name: disappear;
  animation-timing-function: cubic-bezier(1, 0, 1, 1);
  opacity: 0;
}

.overlay .message-item {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.collapse-box {
  z-index: 2;
}

.surface.hide-all .surface-item {
  display: none;
}
.surface.hide-all .surface-item.ninja {
  display: flex;
}

.name-ellipsis {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1000px)
{
  .surface-top {
    flex-direction: column;
    height: auto;
    align-items: stretch;
    gap: 20px;
  }

  .keybinding {
    display: none;
  }
  .space-info {
    margin-bottom: 10px;
  }
  .user-info {
    margin-bottom: 10px;
  }
  .surface-top.collapsed .space-info {
    display: none;
  }
  .surface-top.collapsed .user-info {
    display: none;
  }
  .surface-top.collapsed .collapse-box {
    display: none;
  }
  .surface:not(.cover) .surface-top.collapsed-perf .perf-cell {
    display: none;
  }
  .surface-top.collapsed .help-cell {
    display: none;
  }
  .surface:not(.hide-all).cover {
    overflow: scroll;
    background: black;
    z-index: 5;
    pointer-events: all;
  }
  .surface.cover .surface-top {
    padding-bottom: 50px;
  }
  .surface.cover .space-info {
    width: 100%;
  }
  .surface.cover .collapse-box {
    align-items: stretch;
  }
  .surface.cover .tool-cell {
    display: none;
  }

  #uiOverlay {
    z-index: 6;
  }
}
@media (min-width: 1000px)
{
  .surface-cell.collapsed .collapse-box {
    display: none;
  }
  .to-perf-shelf {
    display:none;
  }

  .help-cell .menu-head {
    flex-direction: row-reverse;
  }

  .help-cell .collapse-box {
    align-items: flex-end;
    width: 100%;
  }
  .help-cell .surface-item.info {
    align-items: flex-end;
  }
  .help-cell .surface-item.info span {
    text-align: right;
  }

  .perf-cell .collapse-box {
    align-items: flex-end;
    width: 100%;
  }

  .hover-tips {
    position: absolute;
    top: 80px;
    z-index: 3;
  }
  .hide-info {
    display: none;
  }
  .user-info {
    right: 30px;
    align-items: flex-end;
  }
}

.standalone-icon svg {
  fill: #ccc;
}

.standalone-icon.hover-brighter:hover svg {
  fill: #fff;
}

.background-black {
  background: #000000;
}

.background-gray-6 {
  background: #141414;
}

.background-gray-8 {
  background: #181818;
}

.background-gray-80 {
  background: #cccccc;
}

.background-red-80 {
  /* h:0 s:80 l:80 */
  background: #f6b6c1;
}

.background-red-80:hover {
  /* h:0 s:80 l:85 */
  background: #f8c8d1;
}

.text-white {
  color: #ffffff;
}

.text-gray-80 {
  color: #cccccc;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.self-center {
  align-self: center;
}

.items-stretch {
  align-items: stretch;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.mr-auto {
  margin-right: auto;
}

.mt-10 {
  margin-top: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.pt-10 {
  padding-top: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-40 {
  padding-bottom: 40px;
}

.flex-1 {
  flex: 1;
}

.flex-300 {
  flex: 0 0 300px;
}

.flex-350 {
  flex: 0 0 350px;
}

.bottom-mask {
  -webkit-mask-image: linear-gradient(to top, transparent, black 20px);
}

.bottom-mask-step {
  -webkit-mask-image: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.5) 20px, rgba(0, 0, 0, 0.5) 40px, black 60px);
}

.x-scroll {
  overflow-x: auto;
}

.no-y-scroll {
  overflow-y: hidden;
}

.y-scroll {
  overflow-y: auto;
}

.minh-40 {
  min-height: 40px;
}

.radius-half {
  border-radius: 50%;
}

.pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}
@media (max-width: 1000px)
{
  .hide-on-narrow {
    display:none;
  }
}
@media (min-width: 1000px)
{
  .narrow-message {
    display: none;
  }
}
